import React, { memo, PropsWithChildren, ReactElement } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionProps as MuiAccordionProps, Accordion as MuiAccordion } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
    },
    accordionSummary: {
        padding: "10px 15px",
        margin: 0,
        borderBottom: '1px solid #f3f3f7',
        borderLeft: "4px solid transparent",
        "&.Mui-expanded": {
          margin: " 0 !important",
          minHeight: "48px",
          borderLeft: "4px solid #0063a3",
          backgroundColor: "#dcedf9",
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0',
        },
      },
      accordionDetails: {
        borderLeft: "4px solid #0063a3"
      },
      table:{},
  }),

);

interface AccordionProps extends Omit<MuiAccordionProps, "title"|"children"> {
    details: JSX.Element;
    title: string|JSX.Element;
}

export const Accordion: React.FC<AccordionProps> = ({
    details,
    title,
    ...props
      }) => {
  const classes = useStyles();
 

  return (
      <MuiAccordion square {...props}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <AccordionSummaryTitle>
              <Typography className={classes.heading} >
                  {title}
              </Typography>
          </AccordionSummaryTitle>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {details}  
        </AccordionDetails>
      </MuiAccordion>
  );
}



interface AccordionSummaryTitleProps {
    controls?: ReactElement | null
}


export const useAccordionStyle = makeStyles(({ typography, transitions, spacing }) =>
    createStyles({
        summary: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignContent: "center",
            margin: 0,
            '.MuiAccordionSummary-content.Mui-expanded & $summary': {
                "&__title": {
                    fontSize: typography.h5.fontSize,
                    fontWeight: typography.h5.fontWeight,
                }
            },

            "&__title": {
                fontSize: typography.body2.fontSize,
                transition: transitions.create('font-size'),
                textTransform: 'capitalize', 
            },

            "&__controls": {
                paddingRight: spacing(1)
            }
        }
    }),
);

export const AccordionSummaryTitle = memo(({ children, controls }: PropsWithChildren<AccordionSummaryTitleProps>) => {
    const styles = useAccordionStyle();

    return (
        <div className={styles.summary}>
            <div className={`${styles.summary}__title`}>
                {children}
            </div>

            {
                controls && <div className={`${styles.summary}__controls`}>
                    {controls}
                </div>
            }
        </div>
    );
});


export default Accordion;