import React from 'react';

import { Router, Route, Switch, Redirect } from 'react-router';
import PrivateRoute from './utils/privateRoute';
import { history } from './store/store';

import Login from './pages/login/login';

import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import Callback from './pages/login/callback';
import Home from './pages/home/home';
import Landing from 'pages/landing/Landing';
import { useSelector } from 'react-redux';


/**
 * For storing internal page redirection inside application
 */
export const Routes = () => {
     let isLoggedIn = useSelector((state: any) => state.auth?.user?.access_token? true: false);
     return (
          <Router history={history} >
               <Switch>
                    <Route exact path="/login" render={() => <Login />} />
                    <Route exact path="/cookie-policy" component={CookiePolicy} />
                    <Route exact path="/callback" component={Callback} />
                    <PrivateRoute path="/home" component={Home}  />
                    <Route exact path="/landing" render={() => (<Landing/>)} />
                    
                    <Route path="*"  render={()=>{
                         return(
                         <Redirect to ="/home" />
                         );
                    }}/>
                    <Route render={() => (isLoggedIn?<Redirect to="/home"/>:<Redirect to="/login"/> )}  />
               </Switch>
          </Router>
     );
};
