import React, { FC, useState, useCallback, useRef, useContext } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColDef, CellClickedEvent } from 'ag-grid-community';
import { withRouter, RouteComponentProps } from 'react-router';
import { useGenericGridViewElements, GridViewAgGridContainer, GridViewFrameworkComponentsType } from '../../../../legacy-components/grid-view/GridView';
import PublisherApplicationService, { IdentityApplication } from './PublisherApplicationService';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IdentityApplicationDeleteDialog, { IdentityApplicationDeleteDialogRef } from './IdentityApplicationDeleteDialog';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';
import { ResourceAbility, useResourceAbility } from '../../../security/authorization';
import TableKeyIcon from '../../../../legacy-components/icons/TableKeyIcon';
import IdentityApplicationDetailDialog, { IdentityApplicationDetailDialogRef } from './IdentityApplicationDetailDialog';
import ApplicationIcon from 'legacy-components/icons/ApplicationIcon';
import ServiceApplicationIcon from 'legacy-components/icons/ServiceApplicationIcon';
interface IdentityApplicationGridProps {
    forUser?: boolean;
    gateway: string
}

const DeleteNotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Application Deleted" />
    )
}

const noDataMessage = <FormattedMessage defaultMessage="No applications available" />;


const Type = ({ value }: { value: string }) => {
    return (
        <Box key={value}     style={{height: '100%',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center'}} >
            {value === "application" ? <ApplicationIcon style={{color:'#0E416C'}}/>: <ServiceApplicationIcon style={{color:'#0E416C'}} />}
        </Box>
        
    )
}
const ViewCredentialsAction: FC<{
    data: IdentityApplication
}> = ({ data }) => {
    const dialogRef = useRef<IdentityApplicationDetailDialogRef>();

    const deleteHandler = useCallback(() => dialogRef.current?.open(), [dialogRef]);

    return (
        <>
            <IdentityApplicationDetailDialog ref={dialogRef} application={data} />

            <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="View Credentials" />}>
                <span>
                    <IconButton onClick={deleteHandler} size="small">
                        <TableKeyIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
}

const DeleteAction: FC<{
    data: IdentityApplication,
    forUser: boolean
    onSubmit: () => void
}> = ({ data, forUser, onSubmit }) => {
    const confirmDialogRef = useRef<IdentityApplicationDeleteDialogRef>();

    const deleteHandler = useCallback(() => confirmDialogRef.current?.open(), [confirmDialogRef]);

    const submitHandler = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    const isDeletionPermitted = data.applicationType?.name !== "resource" ? forUser ? data.isOwnedByUser ? true : false : true : false;
    return (
        <ResourceAbility can="delete" resource={({ Application }) => Application.create.environment[data.organisationName]}>
            <>
                <IdentityApplicationDeleteDialog onSubmit={submitHandler} env={data.organisationName} applicationName={data.applicationName} ref={confirmDialogRef} />

                <Tooltip enterDelay={1000} title={isDeletionPermitted ? <FormattedMessage defaultMessage="Delete Application" /> : <FormattedMessage defaultMessage="You are not permitted to delete this Application" />}>
                    <span>
                        <IconButton disabled={isDeletionPermitted ? false : true} onClick={deleteHandler} size="small">
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>

            </>
        </ResourceAbility>
    );
}


const IdentityApplicationGrid: FC<IdentityApplicationGridProps & RouteComponentProps<{}>> = ({ history, gateway, forUser }) => {
    const intl = useIntl();
    const notificationContext = useContext(SnackNotificationContext);

    const { onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId, createRowActionColumn, refreshStream } = useGenericGridViewElements({
        resolverFn: () => PublisherApplicationService.forApplications(gateway),
        noDataMessage
    });
    const deleteHandler = useCallback(() => {
        notificationContext.show({
            id: "application-grid-delete",
            message: <DeleteNotificationMessage />
        });

        refreshStream();
    }, [notificationContext, refreshStream]);

    const forUserExists = forUser ? forUser : false;

    const [rowActionField] = useState(createRowActionColumn<IdentityApplication>({
        actions: data => [<ViewCredentialsAction data={data} />, <DeleteAction data={data} forUser={forUserExists} onSubmit={deleteHandler} />],
        width: 160
    }));

    const [hasInvite] = useResourceAbility({
        resource: ({ TeamMembers }) => TeamMembers.invite,
      });

    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [
            {
                field: "applicationType.name",
                headerName:"Type",
                width:100,
                cellRenderer: "Type",
            },
            {
            field: "applicationName",
            sortable: true,
            unSortIcon : true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Name"
            }),
            flex: 1
        }, {
            field: "displayName",
            sortable: true,
            unSortIcon : true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Display Name"
            }),
            flex: 1
        }, {
            field: "description",
            headerName: intl.formatMessage({
                defaultMessage: "Description"
            }),
            flex: 1
        }, {
            field: "created",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Created Date"
            }),
            flex: 1
        },
        {
            field: "createdBy",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Created By"
            }),
            flex: 1,
            resizable: true
        },
            rowActionField
        ];

        return columns;
    });

    const onCellClicked = useCallback((event: CellClickedEvent) => {
        if (event.colDef.field !== rowActionField.field) {
            history.push(`/home/applications/${event.data.applicationName}/gateway/${event.data.organisationName}/summary`);
        }
    }, [history, rowActionField]);

    return (
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={{...frameworkComponents, Type}}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
                onCellClicked={onCellClicked}
            />
        </GridViewAgGridContainer>
    )
}

IdentityApplicationGrid.propTypes = {
    forUser: PropTypes.bool
}

export default withRouter(IdentityApplicationGrid);

