import { FormControl, InputLabel, MenuItem, Select, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";
import type { ISpecDetails } from "../../../../service/ProductService";
import React, { useState } from "react";
import { Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export interface EventsSpecReferenceProps {
  redocServerUrl: string;
  specDetails: ISpecDetails;
  productName: string;
  specUrl: string;
}

const usIframeStyle = makeStyles((theme: Theme) =>
  createStyles({
    iframe: {
      minHeight: "calc(60vh - 20px)",
    },
  })
);

export const EventsSpecReference: React.FC<EventsSpecReferenceProps> = ({
  specUrl,
  specDetails,
  productName,
}) => {
  const classes = usIframeStyle();
  
  const  access_token = useSelector((state: any) => state.auth?.user?.access_token);

  const [specLoadingUrl, setSpecLoadingUrl] = useState<string>("");

  const [specEnv, setSpecEnv] = React.useState('prod');

  const handleSpecEnvChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSpecEnv(event.target.value as string);
  };

  useEffect(() => {
    if(specDetails.preProd || specDetails.prod) {
      let url = "";

      if(specEnv === "preProd") {
        if(specDetails?.preProd) {
          url += `${specDetails?.preProd?.fileName}&access_token=${access_token}`;
        }
      } else {
        if(specDetails?.prod) {
          url += `${specDetails?.prod?.fileName}&access_token=${access_token}`;
        }
      }
     
      url = encodeURI(url);
      setSpecLoadingUrl("?spec=" + url)
    }
  }, [specEnv, access_token])

  const Controls = () => (
    <FormControl>
      <InputLabel id="spec-env-select-label">Environment</InputLabel>
      <Select
        labelId="spec-env-select-label"
        id="spec-env-select"
        value={specEnv}
        onChange={handleSpecEnvChange}>
        <MenuItem value={"preProd"}>Pre Prod</MenuItem>
        <MenuItem value={"prod"}>Prod</MenuItem>
      </Select>
    </FormControl>
  );
  return (
    <>
    <Row>
      <div  style={{  marginLeft: "auto"}} >
       <Controls/>
      </div>
    </Row>
    <Row>
      <iframe
        frameBorder="0"
        title="Swagger API Reference"
        height="60vh"
        width="100%"
        className={classes.iframe}
        src={`${specUrl}${specLoadingUrl}`}
      ></iframe>
    </Row>
    </>
  );
};

export default React.memo(EventsSpecReference);
