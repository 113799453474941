import GenericPageTitle from "legacy-components/typography/generic-page-title/GenericPageTitle";
import SubscriptionService, {
  IAPIApplicationSubscription,
} from "libs/resources/subscription/SubscriptionService";
import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab } from "@material-ui/core";
import { ProductSubscriptionListAccordion } from "./ProductSubscriptionListAccordian";
import { productToApplicationMap } from "legacy-components/Helper";
import { Skeleton } from "@material-ui/lab";
import { AsyncActionState, useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { StackSkeleton } from "pages/home/products/components/ProductList.viewer";

export interface IApplicationSubscriptionList {
  applicationName: string;
  consumerKey: string;
  gatewayName: string;
  status: string;
  subscriberEmail: string;
  subscriptionId: string;
  subscriptionRequestedTime: string;
  productTeamName: string;
}

export interface ITransformedSubscriptionList {
  productName: string;
  productId: string;
  applications: Array<IApplicationSubscriptionList>;
}
export const ProductSubscriptionList: React.FC = () => {

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [preProdResponse, {subscribe: preProdSubscribe}] = useAsyncAction(() => SubscriptionService.getTeamSubScriptions(
    false,
    "trimble-pre-prod"
  ));
  const [prodResponse, {subscribe: prodSubscribe}] = useAsyncAction(() => SubscriptionService.getTeamSubScriptions(
    false,
    "trimble-prod"
  ));

  useEffect(() => {
    preProdSubscribe();
  }, [preProdSubscribe]);

  useEffect(() => {
    prodSubscribe();
  }, [prodSubscribe]);

  

  return (
    <>
      <GenericPageTitle
        title={"Subscriptions"}
        subTitle={"View and configure Project Subscriptions"}
      />
      <Tabs indicatorColor="primary" value={value} onChange={handleChange}>
        <Tab
          label="Pre Prod"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab label="Prod" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProductAccordion state={preProdResponse} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductAccordion state={prodResponse} />
      </TabPanel>
    </>
  );
};

interface ProductAccordionProps {
  state: AsyncActionState<IAPIApplicationSubscription[] | null>
 ; 
} 
const ProductAccordion = ({
  state,
}: ProductAccordionProps) => {
  return (
    <>
      <StackSkeleton count={4} response={state} />
      {state?.loaded && state?.value && state?.value?.length >= 1 && (
        <ProductSubscriptionListAccordion
        data={productToApplicationMap(state?.value)}
       />
      )}
      {state?.loaded && (!state?.value || state?.value?.length < 1) && (
        <ErrorMessage message={"No Subscriptions found"} />
      )}
      
    </>
  );
};