import React, { useState } from 'react';
import { Routes } from './routes';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Log } from 'oidc-client';
import * as actions from './store/actions/actions';
import ProfileService from './services/profileService';
import UnifiedAPIService from './services/UnifiedAPIService';
import { IntlProvider } from 'react-intl';
import GenericSnackNotification, { SnackNotificationContext, SnackNotification, ISnackNotificationContext } from './legacy-components/notifications/GenericSnackNotification';
import { CloudConsoleClient } from './libs/utils/http/client/HttpClient';
import { ConfirmProvider } from 'material-ui-confirm';
import AuthService from 'services/authService';
import TagManager from 'react-gtm-module';
import ConsumerClient from 'pages/home/consumer/service/ConsumerClient';

interface LocaleConfig {
     locale: string;
     source: string;

     intl: {
          localeData: "en"
     }
}

const localeConfigs: LocaleConfig[] = [{
     locale: 'en',
     source: 'en',
     intl: {
          localeData: "en"
     }
}];

async function loadLocaleData(config: LocaleConfig) {


     return import(
          `../compiled-lang/${config.source}.json`
     );
}


const initializeAnalytics = () => {
     if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
          TagManager.initialize({
               gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
          });
     }
}



/**
 * @function App
 * @description this function loaded initally
 * @param {*} props
 */
function App(props: any) {


     // Enabling Logger for OIDC Redux in Development Mode

     const id_token = useSelector((state: any) => state.auth?.user?.id_token);
     if (process.env.NODE_ENV === "development") {
          Log.logger = console;
          Log.level = Log.INFO;
     }

     initializeAnalytics();

     let redirectResponse = (response: any) => {
          return response;
     }
     let interceptError = (error: any) => {
          if (error?.response?.status === 401 || error?.response?.status === 0) {
               props.dispatch({ type: actions.clearUserDetails });
               AuthService.signOutRedirect(id_token);
          }
          return Promise.reject(error);
     }
     UnifiedAPIService.interceptors.response.use(redirectResponse, interceptError);
     ProfileService.interceptors.response.use(redirectResponse, interceptError);
     CloudConsoleClient.interceptors.response.use(redirectResponse, interceptError);
     ConsumerClient.interceptors.response.use(redirectResponse, interceptError);

     const [notificationContext] = useState<ISnackNotificationContext>({
          show: value => setSnackNotification(value)
     });

     const [snackNotification, setSnackNotification] = useState<SnackNotification | undefined>()

     return (
          <>
               <SnackNotificationContext.Provider value={notificationContext}>
                    <ConfirmProvider>
                         <Routes />
                    </ConfirmProvider>
                    <GenericSnackNotification notification={snackNotification} />
               </SnackNotificationContext.Provider>
          </>
     );
}


function mapDispatchToProps(dispatch: any) {
     return {
          dispatch
     };
}

export async function bootstapApplication(options: {
     locale: string
}) {
     const config = localeConfigs.find(lang => lang.locale === options.locale) ?? localeConfigs[0];

     const messageModule = await loadLocaleData(config);

     const BootApp = withRouter(connect(null, mapDispatchToProps)(App));

     return (
          <IntlProvider defaultLocale={'en'} locale={config.locale} messages={messageModule.default}>
               <BootApp />
          </IntlProvider>
     );
}
