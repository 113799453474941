import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import CloudRoundedIcon from "@material-ui/icons/CloudRounded";
import { NavLink } from "react-router-dom";
import ProductIcon from "legacy-components/icons/productIcon";

export const ProductNavigation: React.FC = () => {
    const canShowNewProducts = process.env.REACT_APP_NEW_PRODUCT_DISCOVERY === 'true';
    return (
        <>
            <NavLink
                className="navigation-tab"
                key={"developer-marketplace"}
                activeClassName="active"
                to={'/home/marketplace/developer-portal'}
            >
            <ListItem
            button
            >
            <ListItemIcon>
                <CloudRoundedIcon style={{ color: "#fff" }} />,
            </ListItemIcon>
            <ListItemText
                className="sub-menu-text"
                primary={<Box display="flex"><Box flexGrow={1}>Developer Marketplace</Box>
                        </Box>}
            />
            </ListItem>
            </NavLink>
            {canShowNewProducts && (<NavLink
                className="navigation-tab"
                key={"consumer-products"}
                activeClassName="active"
                to={'/home/consumer/products'}
            >
                <ListItem
                    button
                    >
                    <ListItemIcon>
                        <ProductIcon style={{ color: "#fff" }} />,
                    </ListItemIcon>
                    <ListItemText
                        className="sub-menu-text"
                        primary={<Box display="flex"><Box flexGrow={1}>Products</Box>
                                </Box>}
                    />
                    </ListItem>
            </NavLink>)}
        </>
        
    )
}
