import React, { useCallback } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Icon, IconButton, Tooltip, Link } from '@material-ui/core';
import { CellClickedEvent } from "ag-grid-community";
import { useHistory } from 'react-router';
import { ITransformedSubscriptionList } from './ProductSubscriptionList';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import Table, { TableColumn } from 'legacy-components/table/Table';
import { resolveDay } from 'legacy-components/Helper';
import { AccordionSummaryTitle } from 'legacy-components/form/components/accordion-form/AccordionForm';
import { ErrorMessage } from 'modus/components/error-message/ErrorMessage';
import Unsubscriber from '../../components/Unsubscriber/Unsubscriber';

export interface ProductSubscriptionListAccordianProps {
    data?: Array<ITransformedSubscriptionList>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
    },
    accordionSummary: {
        padding: "10px 15px",
        margin: 0,
        borderBottom: '1px solid #f3f3f7',
        borderLeft: "4px solid transparent",
        "&.Mui-expanded": {
          margin: " 0 !important",
          minHeight: "48px",
          borderLeft: "4px solid #0063a3",
          backgroundColor: "#dcedf9",
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0',
        },
      },
      accordionDetails: {
        borderLeft: "4px solid #0063a3"
      },
      table:{},
  }),
);

const canShowNewProducts = process.env.REACT_APP_NEW_PRODUCT_DISCOVERY === 'true';

interface ProductSubscriptionListAccordionProps {
  data: (ITransformedSubscriptionList)[] | undefined;
}

export const ProductSubscriptionListAccordion: React.FC<ProductSubscriptionListAccordionProps> = ({
        data
      }) => {
  const classes = useStyles();
  const history = useHistory();

  const onCellClicked = useCallback((event : CellClickedEvent) => {
    history.push(`/home/applications/${event.data.applicationName}/gateway/${event.data.gatewayName}/summary`);
}, [history]);

const onClickProduct = useCallback((item: ITransformedSubscriptionList) => {
  if (canShowNewProducts) {
    history.push(`/home/consumer/products/${item.productId}`);
  } else {
    history.push(`/home/marketplace/product/${item.productName}`);
  }
}, [history]);

const onEditClicked = useCallback((event : CellClickedEvent) => {
  history.push(`/home/subscriptions/${event.data.subscriptionId}`);
}, [history]);

const Actions = (props: { data: any, onEditClicked: (event: CellClickedEvent) => void, onDeleteClicked: (data: any) => void }) => {
  const handleEditClick = useCallback(() => {
    onEditClicked({ data: props.data } as CellClickedEvent);
  }, [onEditClicked, props.data]);
  

  return (
    <Box className={"actions"}>
      <Tooltip title="Edit">
        <IconButton onClick={handleEditClick}>
          <CreateIcon />
        </IconButton>
      </Tooltip>
      <Box margin="l1" />
      <Unsubscriber
          subscriptionId={props.data.subscriptionId}
          applicationName={props.data.applicationName}
          isIcon={true}
        />
    </Box>
  );
};

type ISubscriptionStatus =
  | "SUBSCRIBED"
  | "PENDING_REVIEW"
  | "REVOKED"
  | "DENIED"
  | "INITIALIZED";

const statusicon: React.FC<{ value: ISubscriptionStatus }> = ({ value }) => {
  return (
    <Box paddingTop={"7px"}>
    <Icon>
      {value === "SUBSCRIBED" &&  <Tooltip placement='right' title="Approved" children={<CheckCircleIcon style={{color: "green"}} />} />}
      {value === "DENIED" &&  <Tooltip placement='right' title="Denied" children={<CancelIcon color='error' />} /> }
      {value === "PENDING_REVIEW" &&  <Tooltip placement='right' title="In Review" children={<PeopleOutlineRoundedIcon />} /> }
      {value === "REVOKED" &&  <Tooltip placement='right' title="Revoked" children={<CancelIcon color='error' />} /> }
      {value === "INITIALIZED" &&  <Tooltip placement='right' title="In Review" children={<PeopleOutlineRoundedIcon />} /> }
    </Icon>
    </Box>
  );
};

  return (
    <div className={classes.root}>
      {data?.length === 0 && <ErrorMessage message={"No subscription found for your Applications"}/>}
      {data?.map((item, index) => (
      <Accordion square >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <AccordionSummaryTitle>
              <Typography className={classes.heading} >
                  <Link onClick={()=>onClickProduct(item)} >
                      {item.productName}
                  </Link>
              </Typography>
          </AccordionSummaryTitle>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box style={{width: "100%"}}>
          <Table
          pagination={false}
          width={"100%"}
              rowData={item.applications}
              noRowsText="No subscription found for your Products"
              frameworkComponents={{ Actions, statusicon }}
            >
                <TableColumn
                flex={3}
                    field="applicationName"
                    headerName="Application Name"
                    cellRenderer="Title"
                    onCellClicked = {(event) => onCellClicked(event)}
                    cellStyle={{color: '#0063a3'}}
                    sortable
                    unSortIcon = {true}
                    filter= "agTextColumnFilter"
                    filterParams= {{
                      suppressAndOrCondition: true,
                      defaultOption: 'contains',
                      filterOptions: ['contains']
                  }}
                />

                <TableColumn
                  flex={3}
                  field="subscriptionRequestedTime"
                  headerName="Subscribed Date"
                  valueFormatter={(params) => resolveDay(params.value)}
                  sortable
                    unSortIcon = {true}
                />

                <TableColumn
                  flex={2}
                  field="status"
                  headerName="Status"
                  cellRenderer="statusicon"
                />

                <TableColumn
                  width={150}
                  headerName="Actions"
                  cellRenderer="Actions"
                />
            </Table>
          </Box>
        </AccordionDetails>
      </Accordion>
            ))}
    </div>
  );
}