import React, {
  useState,
  useEffect,
  useRef,
  RefObject,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Typography, Paper, IconButton, makeStyles, createStyles, Box, Tabs, Tab } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { useAsyncAction, AsyncLoader } from "../../../legacy-components/form/action/AsyncAction";

import {
  useResourceAbility,
  useRole,
} from "libs/security/authorization/Permission";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { useHistory, withRouter } from "react-router-dom";

import AddUserDialog, { AddUserDialogRef } from "./AddUserDialog";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import GenericPageHeader from "legacy-components/page-headers/GenericPageHeader";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../../legacy-components/loader";
import { UserRoleEdit } from "../sector/users/UserRoleEdit";
import SwipeableViews from "react-swipeable-views";
import { theme } from "themes/mui";
import { GridViewAgGridContainer, EnvironmentStatusBadgeCellRenderer } from "legacy-components/grid-view/GridView";
import DetailForm from "./forms/DetailForm";
import { TeamDetail } from "./models/team.model";
import TeamService from "libs/resources/team/TeamService";
import TeamServices, { IAPITeam } from "./TeamService"
import { StatusBadge } from "legacy-components/badges/StatusBadge";
import DivisionService, { Division } from "../sector/division/DivisionService";
import { Alert } from "@material-ui/lab";

enum FrameworkComponentsType {
  LoadingOverlay = "LOADING_OVERLAY",
  NoRowsOverlay = "NO_ROWS_OVERLAY",
  RowData = "ROW_DATA",
  RowActions = "ROW_ACTIONS",
}

type FrameworkComponentState = {
  [K in FrameworkComponentsType]: (...params: any) => JSX.Element;
};

interface IGripOverlayPropRef {
  error: boolean;
}

const LoadingComponent = () => <Loader />;
const NoRowsComponent = (props: {
  gridOverlayPropRef: RefObject<IGripOverlayPropRef>;
}) => {
  return (
    <Typography variant="body2">
      {props.gridOverlayPropRef?.current?.error ? (
        <FormattedMessage defaultMessage="No team members available" />
      ) : (
          <FormattedMessage defaultMessage="No users available" />
        )}
    </Typography>
  );
};

const DataComponent = (data: any) => {
  return <StatusBadge align="left" status={{
    kind: "access",
    key: data.data?.gateways[0]?.approval
}} />
};

const getRowNodeId = (data: { id: string }) => data.id;

const UserGrid = (props: any) => {
  const intl = useIntl();
  const [rolePermission, state] = useRole();
  const confirm = useConfirm();
  const history = useHistory();

  const [can, { loaded, intermediate }] = useResourceAbility({
    resource: ({ TeamMembers }) => TeamMembers.invite,
  });

  const RowActionComponent = (rowProps: {
    data: any;
    options: {
      onDeleteHandler: (data: any) => () => void;
    };
  }) => {
    return (
      <React.Fragment>
        <UserRoleEdit user={rowProps.data} team={props.teamName} onSuccess={props.onSuccess} />
        <IconButton
          onClick={rowProps.options.onDeleteHandler(rowProps.data)}
          size="small"
        >
          <RemoveCircleOutlineOutlinedIcon />
        </IconButton>
      </React.Fragment>
    );
  };

  const [frameworkComponents] = useState<FrameworkComponentState>({
    [FrameworkComponentsType.LoadingOverlay]: LoadingComponent,
    [FrameworkComponentsType.NoRowsOverlay]: NoRowsComponent,
    [FrameworkComponentsType.RowData]: DataComponent,
    [FrameworkComponentsType.RowActions]: RowActionComponent,
  });

  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  let teamName = useRef<string | undefined>(rolePermission?.team);

  useEffect(() => {
    teamName.current = rolePermission?.team;
    if (can.create()) {
      if (props.teamName) {
        teamName.current = props.teamName;
      }
    }
  }, [props.teamName, rolePermission]);

  let [collection, { subscribe }] = useAsyncAction<IAPITeam[]>(() =>
    TeamService.getUserList(props.teamName ? props.teamName : teamName.current)
  );

  const onDelete = useCallback(
    (data: any) => {
      return new Promise((resolve, reject) => {
        UnifiedAPIService.delete(
          UnifiedAPI.parseRequestURL(UnifiedAPI.delete_user, {
            userId: data.id,
            teamName: data.team
          }),
          {} //Params to replace in URL
        ).then(
          () => {
            resolve(null);
            setTimeout(() => {
              props.onSuccess && props.onSuccess();
          }, 2000);
            
            //history.go(0);
          },
          (error) =>
            reject(error?.message ?? "Unknown error occured while deleting")
        );
      });
    },
    [history]
  );

  const columnDefs = useRef<ColDef[]>([]);

  useEffect(() => {
    if (loaded || intermediate) {
      columnDefs.current = [];
      columnDefs?.current.push(
        {
          field: "email",
          sortable: true,
          headerName: intl.formatMessage({
            defaultMessage: "Email Address",
          }),
          flex: 1,
        },
        {
          field: "role",
          sortable: true,
          headerName: intl.formatMessage({
            defaultMessage: "Role",
          }),
          flex: 1,
          type: 'rightAligned',
          cellRendererFramework: EnvironmentStatusBadgeCellRenderer
        },
        {
          headerName: intl.formatMessage({
            defaultMessage: "Status",
          }),
          cellRenderer: FrameworkComponentsType.RowData,
          flex: 1,
          type: 'rightAligned'
        }
      );
      if (can.create()) {
        columnDefs?.current.push({
          field: "actions",
          headerName: intl.formatMessage({
            defaultMessage: "Actions",
          }),
          flex: 1,
          type: "rightAligned",
          cellRenderer: FrameworkComponentsType.RowActions,
          cellRendererParams: {
            options: {
              onDeleteHandler: (data: any) => {
                return (event: MouseEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  confirm({
                    description:
                      "Do you want to remove " +
                      data.name +
                      " from " +
                      data.team +
                      " team?",
                  }).then(() => {
                    onDelete(data);
                  });
                };
              },
            },
          },
        });
      }
    }
  }, [loaded, intermediate, can.create()]);

  const gridOverlayPropRef = useRef<IGripOverlayPropRef>({
    error: false,
  });

  useEffect(() => {
    if (gridApi) {
      gridOverlayPropRef.current.error = collection.error;

      if (collection.error) {
        gridApi.setRowData([]);
      } else if (collection.value) {
        gridApi.setRowData(collection.value);
      }
    }
  }, [collection, gridApi]);

  useEffect(() => {
    let unsubscribe = () => { };
    if (state.loaded || state.intermediate) {
      unsubscribe = subscribe();
    }
    return () => unsubscribe();
  }, [subscribe, state.loaded || state.intermediate]);

  const onGridReady = (event: GridReadyEvent) => setGridApi(event.api);

  return (
    <>
      <GridViewAgGridContainer>
        {(loaded || intermediate) && columnDefs?.current.length > 0 && (
          <AgGridReact
            columnDefs={columnDefs?.current}
            frameworkComponents={frameworkComponents}
            loadingOverlayComponent={FrameworkComponentsType.LoadingOverlay}
            noRowsOverlayComponent={FrameworkComponentsType.NoRowsOverlay}
            noRowsOverlayComponentParams={{ gridOverlayPropRef }}
            suppressRowClickSelection={true}
            suppressCellSelection={true}
            suppressRowHoverHighlight={true}
            getRowNodeId={getRowNodeId}
            undoRedoCellEditing={true}
            onGridReady={onGridReady}
          // onCellValueChanged={editRole}
          />
        )}
      </GridViewAgGridContainer>
    </>
  );
};

const UserList = (props: any) => {
  const [, setReloader] = useState<boolean>(false);

  const reload = useCallback(() => {
    setIsFormCompleted(Math.random().toString());
    setReloader(true);
    setTimeout(() => {
      setReloader(false);
    }, 500);
  }, []);

  let title: string = "My Team";
  if (props.match.params.displayName) {
    title = props.match.params.displayName;
  }


  const useStyles = makeStyles(({ palette }) =>
    createStyles({
      root: {
        flexGrow: 1
      },
      title: {
        marginBottom: "15px"
      },
      tabs: {
        marginBottom: '30px'
      },
      container: {
        marginBottom: '10px'
      },
      icon: {
        color: palette.text.primary
      }
    }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const [views] = React.useState([

    {
      mode: "TEAM DETAIL"
    },
    {
      mode: "MEMBERS",
    }
  ]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const [value, setValue] = React.useState(1);
  const classes = useStyles();

  const [teamDetails, setTeamDetails] = useState<TeamDetail>();
  const [divisions, setDivisions] = useState<Array<{
    key?: string,
    value?: string
}>>();
  const getTeamDetails = (teamDetail: TeamDetail) => {
    // teamDetail.divisionName = teamDetail.division?.name
    setTeamDetails(teamDetail);
    return teamDetail;
  }

  const onSuccessForm = () => {
    setIsFormCompleted(Math.random().toString());
  };
  const [isFormCompleted, setIsFormCompleted] = useState<string>();
  const [response, { subscribe }] = useAsyncAction(() => TeamServices.forTeamDetails(props.match.params.teamName),
    {
      mapFn: getTeamDetails
    });
  useEffect(() => {
    const unsubscribe = subscribe()

    return () => unsubscribe();
  }, [subscribe, isFormCompleted]);
  const confirmDialogRef = useRef<AddUserDialogRef>();

  


useEffect(() => {
    DivisionService.getDivisions().subscribe(response => {
        setDivisions(response.map(
            (data: Division) => {
                return { "key": data.name + "," + data.baCode, "value": data.name + ", " + data.baCode }
            }
        )
        );
    }, () => {
    });
}, [isFormCompleted]);

  return (
    <>
    {response?.status == "error" && <Alert severity='error'>The following team is restricted to the following user for the selected team</Alert>}
    <AsyncLoader state={response} loader={<></>}>
      <div>

        <GenericPageHeader
          title={<div>{title}</div>}
          controls={
            <>
              <AddUserDialog ref={confirmDialogRef} onSuccess={reload} />
            </>
          }
        />
        {
          views && (<Tabs
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            {views.map((view: { mode: string }) => (<Tab label={view?.mode} key={view.mode} />))}
          </Tabs>)
        }
        {
          (views) && (
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {views && views.map((view: any, index: number) => (
                <TabPanel value={value} index={index} key={index}>

                  {view.mode.toLowerCase() == "members" ?
                    <>
                      {(
                        <UserGrid onSuccess={reload} teamName={props.match.params.teamName} />
                      )}
                    </>
                    : (

                      <Paper>
                        <DetailForm teamDetail={teamDetails} divisions = {divisions} onSuccess={onSuccessForm} />
                      </Paper>

                    )}
                </TabPanel>
              ))
              }
            </SwipeableViews>
          )
        }
      </div>
    </AsyncLoader>
    </>
  );
};

UserList.propTypes = {};

export default withRouter(UserList);
