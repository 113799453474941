import React, { FC, useContext, useState, useEffect } from 'react'
import { Field, FormRenderProps } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ApplicationDetailFormData } from '../models/application.model';
import PropTypes from "prop-types";
import PublisherApplicationService, { IdentityApplicationEnvironmentType } from '../PublisherApplicationService';
import { useResourceAbility } from 'libs/security/authorization/Permission';
import { IApplication, ProductService } from 'pages/home/consumer/service/ProductService';
import { sanitize } from 'dompurify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ApplicationService from '../ApplicationService';
import { from } from 'rxjs';
import { ValidatorBuilderContext } from 'legacy-components/form/validator/Validator';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import FormTextInput from 'legacy-components/form/input/FormTextInput';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from 'legacy-components/form/container/FormContainer';

export interface AppResponse {
    applicationName: string;
    environment: string;
}
interface ApplicationBasicFormProps {
    stepperId: string,
    productName: string,
    onCancel: () => void;
    onSuccess?: (application: AppResponse) => void;
}

type GatewayType =  "trimble-prod"|"trimble-pre-prod"
const ApplicationBasicForm: FC<ApplicationBasicFormProps> = ({ stepperId, productName, onCancel, onSuccess }) => {
    const validator = useContext(ValidatorBuilderContext);
    const [dataFields] = useState(["__applicationInfo"]);
    const intl = useIntl();
    

    const onSubmit: FormContainerSubmitHandler = ({ stepperOptions }) => {
        return (data, form) => {
            return new Promise((resolve) => {
                const formState = form.getState();

                if (formState.pristine) {
                    resolve(false);
                } else if (data.environment) {
                    if (stepperOptions?.initilizeContent?.values?.__applicationInfo?.name) {
                        PublisherApplicationService.environments[data.environment as GatewayType].update(stepperOptions.initilizeContent.values.__applicationInfo.name, {
                            description: sanitize(data.description??""),
                            displayName: sanitize(data.displayName??""),
                            name: stepperOptions.initilizeContent.values.__applicationInfo.name
                        }).then(() => resolve(false), error => {
                            resolve({
                                [FORM_ERROR]: error
                            });
                        });
                    } else {
                        const applicationType = "application"; // stepperOptions?.formData?.["application-type"]?.__applicationTypeInfo?.applicationType;

                        if (applicationType) {
                            PublisherApplicationService.environments[data.environment as GatewayType].create({
                                name: data.name,
                                description: sanitize(data.description ?? ""),
                                displayName: sanitize(data.name ?? "") ,
                                applicationType
                            }).then((result: any) => {
                                ApplicationService.publish("publish", data.environment, data.name, {
                                    logoutUri:[],
                                    redirectUri:[],
                                    grantTypes: ["client_credentials"]
                                }).then((result: any) => {
                                    form.mutators.setValue("__applicationInfo", {
                                        id: result?.applicationId,
                                        name: data.name,
                                        environment: data.environment
                                    });
                                    onSuccess && onSuccess({
                                        applicationName: data.name,
                                        environment: data.environment,
                                        
                                    });
                                    resolve(false);

                                    onCancel();
                                })
                                
                            }, (error: any) => {
                                resolve({
                                    [FORM_ERROR]: error
                                });
                            });
                        } else {
                            resolve({
                                [FORM_ERROR]: intl.formatMessage({
                                    defaultMessage: "Field Application Type is required"
                                })
                            });
                        }
                    }
                } else {
                    resolve({
                        [FORM_ERROR]: intl.formatMessage({
                            defaultMessage: "Field Application Environment is required"
                        })
                    });
                }
            })
        }
    }

    const FormComponents = (formRenderProps: FormRenderProps<ApplicationDetailFormData, {}>, containerOptions: FormContainerOptions) => {
        const intl = useIntl();
        const formState = formRenderProps.form.getState();
        const [can, state] = useResourceAbility({
            resource: ({ Application }) =>
                Application.list.environment["trimble-prod"]
        });

        const [applicationEnvironments] = useState<Array<{
            key: IdentityApplicationEnvironmentType,
            value: string
        }>>([
            {
                "key": "trimble-pre-prod",
                "value": intl.formatMessage({
                    defaultMessage: "Pre-Prod Environment"
                })
            },{
                "key": "trimble-prod",
                "value": intl.formatMessage({
                    defaultMessage: "Prod Environment"
                })
            }]);
            
        
            useEffect(() => {
            if(productName != undefined){
                ProductService.getProductDetail(productName).toPromise()
            .then(
                (output) => {
                    applicationEnvironments.map((item, index) => {
                        const gateway = output.data.publishedInfo.filter((info) => info.gateway == item.key.toUpperCase());
                        if(gateway.length == 0  ) {
                            delete applicationEnvironments[index];
                        }
                    })
                },
                (error) => {
                }
            );
            } 
            }, [productName, applicationEnvironments]);

        return (<>
            <FormInputContainer padding="inset">
                <Field
                    name="environment"
                    type="text"
                    disabled={!!formState.values.__applicationInfo?.name}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Application Environment"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Application Environment"
                    })}
                    options={applicationEnvironments}
                    validate={validator.from({
                        required: true
                    })}
                />
            </FormInputContainer>
            <FormInputContainer inline={true} padding="inset-content">
                <Field
                    name="name"
                    type="text"
                    disabled={!!formState.values.__applicationInfo?.name}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Name"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Name"
                    })}
                    helperText={intl.formatMessage({
                        defaultMessage: "Name cannot be changed once created"
                    })}
                    validate={validator.from({
                        required: true,
                        minLength: 3,
                        maxLength: 64,
                        pattern: ({ GenericResourceName }) => GenericResourceName
                    })}
                />

            </FormInputContainer>
            <FormInputContainer padding="inset-content">
                <Field
                    name="description"
                    type="text"
                    multiline
                    rows={2}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Description"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Description"
                    })}
                    validate={validator.from({
                        required: true,
                        maxLength: 500
                    })}
                />
            </FormInputContainer>

            <ActionInliner padding="inset">
                <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation>
                    <FormattedMessage defaultMessage="Create" />
                </Button>
                
            </ActionInliner>
            <Box pb={2}/>
        </>);
    }

    return <FormContainer previewErrors={true} stepperId={stepperId} dataFields={dataFields} FormProps={{ onSubmit }} children={FormComponents} />;
}

ApplicationBasicForm.propTypes = {
    stepperId: PropTypes.string.isRequired
}

export default ApplicationBasicForm;