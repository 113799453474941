import { Box } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { AsyncActionState } from "legacy-components/form/action/AsyncAction";
import { ApplicationCreationPage } from "pages/home/applications/pages/application-creation-page/ApplicationCreationPage";
import { ProductDetail } from "pages/home/products/components/Product.model";
import React from "react";
import { ApplicationListSkeleton, UnsubscribedApplicationList } from "../components/unsubscribed-application-list/UnsubscribedAppicationList";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";


interface SubscriberPageProps {
    response: AsyncActionState<AxiosResponse<ProductDetail> | null>    ;
    loadProduct?: (stream?: boolean) => () => void;
}
export const SubscriberPage:React.FC<SubscriberPageProps> = ({response, loadProduct}) => {
    return (
        <Box>
            <GenericPageTitle
                title={`Subscribe to ${response?.value?.data?.displayName ?? response.value?.data?.name ?? ""}`}
                noSubTitle
                description={"Select the Application you want to subscribe with this product" }
                controls={
                    <ApplicationCreationPage variant="outlined" onSuccess={() => loadProduct && loadProduct()} />
                }
            />
            {!response?.loading && response?.value?.data &&  (<UnsubscribedApplicationList product={response?.value?.data} />)}
            {response?.loading && (<ApplicationListSkeleton/>)}
        </Box>
    );
}
