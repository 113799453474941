import React from "react";
import type { PropsWithChildren } from "react";
import useTypographyStyle from "./Typography.style";
import { Typography as MuiTypography } from "@material-ui/core";
import clsx from "clsx";

/* eslint-disable-next-line */
export interface TypographyProps {
  type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body" | "lead" | "small";
  weight?: "regular" | "semiBold" | "bold";
  color?:
    | "inherit"
    | "initial"
    | "primary"
    | "secondary"
    | "error"
    | "textPrimary"
    | "textSecondary"
    | undefined;
  align?: "left" | "right" | "center";
  transform?: "upper" | "lower" | "capitalize" | "none";
  className?: string;
}

/**
 * Usage details:
 * Heading 1 : Most popular for top level headers. Titles within large content containers.
 * Heading 2 : Sub-sections and field group headings.
 * Heading 3 : Sub-sections, card titles.
 * Heading 4 : Sub-sections, card titles.
 * Heading 5 :  Sub-sections, card titles
 * Heading 6 : Sub-sections, card titles, overlines, table headings.
 * Lead : Paired with display font. Used as subtitle.
 * Body : Body and paragraph style.
 * Small : Body and paragraph style. Table content, form labels, card captions, disclaimers, time stamps.
 * Mini : Captions, labels, overlines, disclaimers.
 */

export const Typography: React.FC<PropsWithChildren<TypographyProps>> = ({
  children,
  type = "body",
  color = "inherit",
  weight,
  //align = "left",
  transform = ["body", "lead", "small"].indexOf(type) === -1
    ? "capitalize"
    : "none",
  className,
  ...options
}) => {
  const classes = useTypographyStyle();
  return (
    <MuiTypography
      color={color}
      {...options}
      className={clsx(
        classes.root,
        classes[type],
        classes[weight ?? "weight"],
        classes[transform],
        className
      )}
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
