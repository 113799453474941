import React, { useCallback, useContext } from "react";
import {
  IconButton,
  Tooltip,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import AlertService from "legacy-components/alert/AlertService";
import Typography from "legacy-components/typography/Typography";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";
import ConsumerSubscriptionService from "../../services/ConsumerSubscriptionService";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

export interface UnsubscriberProps {
  subscriptionId: string;
  applicationName: string;
  isIcon: boolean;
}

export const Unsubscriber: React.FC<UnsubscriberProps> = ({
  subscriptionId,
  applicationName,
  isIcon
}) => {
  const { open, toggle } = useDialogProps();
  const history = useHistory();

  const removeSubscription = useCallback(() => {
    ConsumerSubscriptionService.deleteSubscription(subscriptionId)
      .toPromise()
      .then(
        (output) => {
          AlertService.success({
            message: `Subscription is removed for ${applicationName} Application`,
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
          setTimeout(() => {
            history.push("/home/subscriptions");
          }, 1000);
        },
        (error) => {
          AlertService.error({
            message: "Failed to revoke Subscription ",
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
        }
      );
  }, [subscriptionId, toggle, applicationName]);

  return (
    <>
      <Tooltip title={"Delete Subscription"}>
        {isIcon ? (
          <span>
            <IconButton onClick={toggle} size="small" data-testid={`${applicationName}-delete-btn`}>
              <DeleteIcon />
            </IconButton>
          </span>
        ) : (
          <Button onClick={toggle} variant="contained" color="primary">
                <FormattedMessage defaultMessage="Unsubscribe" />
          </Button>
        )}
      </Tooltip>
      <Dialog open={open} title="Remove Subscription" onClose={toggle}>
        <Divider></Divider>
        <>
          <DialogContent>
            <Typography type="body" align="center">
              <b>Are you sure you want to remove this subscription?</b><br/>
              Removing a subscription may affect API traffic in your environments.<br/>
              You should expect a 403 error when a subscription is not active.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={toggle}>
              Cancel
            </Button>
            <Button onClick={removeSubscription} color="primary" variant="contained"> Remove Subscription </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default Unsubscriber;