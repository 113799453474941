import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Select, MenuItem, TextField, Typography, IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TrimbleColors } from 'legacy-components/TrimbleColors';
import { resolveTextToFilter } from './filter';
import EventService from '../service/EventService';
import { useValidateFilterCondition } from './EventFilterValidation';

interface EventDrawerProps {
    open: boolean;
    onClose: () => void;
    serviceList?: { [key: string]: string[] };
    subscriptionId: string;
    applicationId: string| undefined;
    onChange: () => void;
}

const EventDrawer: React.FC<EventDrawerProps> = ({ open, onClose, serviceList, subscriptionId, applicationId, onChange }) => {
    const [selectedServiceInDrawer, setSelectedServiceInDrawer] = useState<string>('');
    const [selectedDeploymentInDrawer, setSelectedDeploymentInDrawer] = useState<string>('');
    const [description, setDescription] = useState<string>('');    
    const [filter, setFilter] = useState<string>('');
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const { validateFilterCondition } = useValidateFilterCondition();
    const [isFilterValid, setIsFilterValid] = useState<boolean>(true);

    const [serviceDeploymentList, setServiceDeploymentList] = useState<string[]>(['Dev', 'Qa', 'Stage', "Prod"]);

    useEffect(() => {
        setIsFormValid(
            selectedServiceInDrawer !== '' &&
            selectedDeploymentInDrawer !== '' &&
            description.trim() !== '' &&
            isFilterValid === true
        );
    }, [selectedServiceInDrawer, selectedDeploymentInDrawer, description, filter, isFilterValid]);

    const handleServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedService = event.target.value as string;
        setSelectedServiceInDrawer(selectedService);
        if (serviceList!==undefined)
            setServiceDeploymentList(serviceList[selectedService]);
        setSelectedDeploymentInDrawer('');
    };

    const handleDeploymentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDeploymentInDrawer(event.target.value as string);
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'description') {
            setDescription(value);
        }
        if (name === 'filter'){
            setFilter(value);
            if(value===''){
                setIsFilterValid(true);
            }else{
            setIsFilterValid(false);
            }
        }
    };

    const handleFormSubmit =  (event: React.FormEvent) => {
        event.preventDefault();
        if (isFormValid) {
            
            try {
                const requestPayload = {
                    description: description,
                    environment: selectedDeploymentInDrawer,
                    applicationId: applicationId,
                    service_version: selectedServiceInDrawer,
                    filter: filter !== '' ? resolveTextToFilter(filter) : null,

                };

                EventService.createEventSubscription(requestPayload, subscriptionId).toPromise()
                .then(()=>{
                    onChange();
                }); 
                onClose();
                
            } catch (error) {
                console.error("Error creating subscription:", error);
            }
        }
        setServiceDeploymentList([]);
        setSelectedServiceInDrawer('');
        setFilter('');
        setDescription('');
    };

    const handleVerifyClick = () => {
        //api call to verify filter scenarios
        const validationResult = validateFilterCondition(filter);
        setIsFilterValid(validationResult);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} style={{ width: 950 }}>
            <Box p={2} style={{ width: 933, paddingLeft: 54, maxHeight: '100vh', overflowY: 'auto' }}>
                <Typography variant="h6" style={{ marginBottom: 16, marginLeft:1 }}>
                    New Event Configuration
                </Typography>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300, marginBottom: 16 }}>
                    Create a new event specific to your environment and service
                </Typography>
                <form onSubmit={handleFormSubmit}>
                    <Box mb={2}>
                        <Typography style={{ marginBottom: 8, fontSize: '18px', fontWeight: 300 }}>
                            Service Versions
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={selectedServiceInDrawer}
                            onChange={handleServiceChange}
                            displayEmpty
                            style={{width: 800}}
                        >
                            <MenuItem value="" disabled>
                                <Typography style={{fontSize: '18px', fontWeight: 300, color: '#999' }}>Select a service</Typography>
                            </MenuItem>
                            {serviceList && Object.entries(serviceList).map(([version, deployments], index) => (
                                <MenuItem key={index} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '18px', fontWeight: 300 }}>
                            Service Deployment
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={selectedDeploymentInDrawer}
                            onChange={handleDeploymentChange}
                            displayEmpty
                            style={{width: 800}}
                        >
                            <MenuItem value="" disabled>
                                <Typography style={{fontSize: '18px', fontWeight: 300, color: '#999' }}>Select a service deployment</Typography>
                            </MenuItem>
                            {serviceDeploymentList.map((deployment, index) => (
                                <MenuItem key={index} value={deployment}>
                                    {deployment}
                                </MenuItem>
                            ))}
                        </Select>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '18px', fontWeight: 300 }}>
                            Description
                        </Typography>
                        <TextField
                            name="description"
                            onChange={handleFormChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            placeholder="Add short description here"
                            variant="outlined"
                            style={{ backgroundColor: '#fff', borderRadius: 4, width: 800}}
                        />
                    </Box>
                    <Typography style={{ marginBottom: 5, marginTop: 50, fontSize: '18px', fontWeight: 600 }}>
                        Filter (Optional)
                        <IconButton size="medium" style={{ marginLeft: 8 }}>
                            <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2} style={{width: 800}}>
                        <TextField
                            name="filter"
                            onChange={handleFormChange}
                            value={filter}
                            fullWidth
                            rows={4}
                            placeholder="Add your filter here"
                            variant="outlined"
                            style={{ backgroundColor: '#fff', borderRadius: 4, flex: 1 }}
                        />
                        <Button
                            variant="outlined"
                            onClick={handleVerifyClick}
                            style={{
                                marginLeft: 16,
                                border: `2px solid ${TrimbleColors.BlueDark}`,
                                color: TrimbleColors.BlueDark,
                                backgroundColor: 'transparent',
                                textTransform: 'none',
                                padding: '12px 24px',
                                fontSize: '20px',
                                minWidth: '120px'
                            }}
                        >
                            Verify
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={2} style={{ marginTop: 100, marginRight: 50 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isFormValid}
                            style={{
                                backgroundColor: TrimbleColors.BlueDark,
                                color: '#FFFFFF',
                                textTransform: 'none',
                                padding: '12px 24px',
                                fontSize: '20px',
                                minWidth: '120px',
                                opacity: isFormValid ? 1 : 0.5,
                                cursor: isFormValid ? 'pointer' : 'not-allowed'
                            }}
                        >
                            Add new Configuration
                        </Button>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default EventDrawer;
